import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';
import Logo from '../Logo';

const StyledWrapper = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 0 auto 100px;
`;
const StyledTitle = styled.div`
  margin-bottom: 20px;
  font-size: 30px;
`;

const StyledList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
`;

const StyledLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 330px;
  max-width: 100%;
  height: 100px;
`;

const COMPANY_DATA_QUERY = graphql`
  query IntroducingCompanyData {
    allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "projects-introducing-company" } }
      }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            mainTitle
            introductingCompany {
              name
            }
          }
        }
      }
    }
  }
`;

const IntroducingCompany: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.IntroducingCompanyDataQuery>(
    COMPANY_DATA_QUERY,
  ) as DataContainsNode;
  const { frontmatter } = useTransformNode(data);
  const IntroducingCompanyData = frontmatter?.introductingCompany;

  return (
    <StyledWrapper>
      <StyledTitle>{frontmatter?.mainTitle}</StyledTitle>
      <StyledList>
        {IntroducingCompanyData?.map((item, index) => (
          <StyledLogo key={index}>
            {item?.name && <Logo name={item.name} />}
          </StyledLogo>
        ))}
      </StyledList>
    </StyledWrapper>
  );
};

export default IntroducingCompany;
